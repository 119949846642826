import axios from "axios";
import { AXIOS } from "../../../axios";
import ls from "localstorage-slim";
import router from "../../../router";
import env from "@/env";
import {
  successToast,
  warningToast,
  errorToast,
} from "@/helpers/toastifications";

import {
  storeCookies,
  getCookies,
  deleteCookie,
} from "@/helpers/store-cookies.js";

ls.config.encrypt = true;

// staging baseUrl
// const BaseURL = '/';

// production baseUrl
const BaseURL = env.baseURL;

export default {
  namespaced: true,
  state: {
    user: localStorage.getItem("user_data") || "",
    token: getCookies("access_token") || "",
    isLogged: false,
    days: "",
    loading: false,
    industrial_types: [],
    invalidOtp: false,
    emailExistMsg: "",
    loginLoading: false,
    playType: false,
    currencies: [],
    role: "",
    permissions: {},
    main_currency: JSON.parse(localStorage.getItem("cur_data")),
    currency: {},
  },
  getters: {
    // getUser: state => JSON.parse(state.user),
    isLogged: (state) => state.isLogged,
    isAuthenticated: (state) => state.token,
    days: (state) => state.days,
    loading: (state) => state.loading,
    industrial_types: (state) => state.industrial_types,
    invalidOtp: (state) => state.invalidOtp,
    emailExistMsg: (state) => state.emailExistMsg,
    loginLoading: (state) => state.loginLoading,
    playType: (state) => state.playType,
    currencies: (state) => state.currencies,
    role: (state) => state.role,
    permissions: (state) => state.permissions,
    currency: (state) => state.currency,
  },
  mutations: {
    loginMutation: (state) => {
      state.isLogged = true;
    },

    logoutMutation: (state) => {
      state.isLogged = false;
    },

    set_days: (state, data) => {
      state.days = data;
    },

    set_loading: (state, data) => {
      state.loading = data;
    },

    set_industrial_types: (state, data) => {
      state.industrial_types = data;
    },

    set_invalidOtp: (state, data) => {
      state.invalidOtp = data;
    },

    set_emailExistMsg: (state, data) => {
      state.emailExistMsg = data;
    },

    set_loginLoading: (state, data) => {
      state.loginLoading = data;
    },

    set_playType: (state, data) => {
      state.playType = data;
    },

    set_currencies: (state, data) => {
      state.currencies = data;
    },

    set_role: (state, data) => {
      state.role = data;
    },

    set_permissions: (state, data) => {
      state.permissions = data;
    },
    set_currency: (state, data) => {
      state.currency = data;
    },
  },
  actions: {
    loginAction: async ({ commit, rootState, dispatch, state }, payload) => {
      localStorage.removeItem("companyLogo");
      localStorage.removeItem("companyName");
      localStorage.removeItem("FullName");
      localStorage.removeItem("xtenant");
      localStorage.removeItem("email");
      localStorage.removeItem("company_address");
      ls.remove("per");
      ls.remove("randomID");
      ls.remove("code");
      ls.remove("perToken");
      ls.remove("curr_name");
      ls.remove("symbol");
      commit("set_loginLoading", true);
      try {
        const res = await axios.post(`${BaseURL}api/Login`, {
          email: payload.user.email,
          password: payload.user.password,
        });
        if (res.status === 200) {
          if (res.data.UserData.message === "Unauthorized") {
            warningToast("Email or Password is not correct!");
            commit("set_loginLoading", false);
          } else {
            localStorage.setItem("xtenant", res.data.GetTenantID);
            localStorage.setItem("isActive", res.data.isActive);
            ls.set("randomID", res.data.DaysTrail);
            localStorage.setItem("codeee", res.data.code);
            ls.set("code", res.data.code);

            ls.set("SubscriptionStatus", res.data.SubscriptionStatus);

            commit("set_days", res.data.TrialDays);
            storeCookies("access_token", res.data.UserData.accessToken);
            commit("loginMutation");
            localStorage.setItem("FullName", res.data.FullName);
            localStorage.setItem("layout", "dashboard-layout");
            localStorage.setItem("email", res.data.UserData.user.Email);
            localStorage.setItem("tierId", res.data.tier);
            localStorage.setItem("role", res.data.UserData.user.Role);
            localStorage.setItem("locale", "en");

            localStorage.setItem(
              "company_address",
              res.data.UserData.user.CompanyBillingSettings
            );

            if (res.data.UserData.user.CompanyLogo) {
              localStorage.setItem(
                "companyLogo",
                `${
                  res.data.UserData.user.CompanyLogo
                }?XTenant=${localStorage.getItem("xtenant")}`
              );
            }

            localStorage.setItem(
              "companyName",
              res.data.UserData.user.CompanyName
            );
            rootState.companyLogo = `${
              res.data.UserData.user.CompanyLogo
            }?XTenant=${localStorage.getItem("xtenant")}`;
            rootState.companyName = res.data.UserData.user.CompanyName;

            ls.set("perToken", res.data.UserData.user.Role);

            dispatch(
              "dashboard/set_currency_data_action",
              res.data.CurrencyData
            );

            localStorage.setItem(
              "cur_data",
              JSON.stringify(res.data.CurrencyData)
            );

            if (res.data.UserData.user.Role == "PM") {
              window.location.replace("/projects");
            } else if (res.data.UserData.user.Role == "reg_user") {
              window.location.replace("/sales");
            } else if (
              res.data.UserData.user.Role == "admin" ||
              res.data.UserData.user.Role == "accountant"
            ) {
              window.location.replace("/dashboard");
            }
          }
        }
      } catch (error) {
        error.message;
        if (error.response.status === 409) {
          warningToast(error.response.data.message);
        } else {
          errorToast("Something went wrong!");
        }
        commit("set_loginLoading", false);
      }
    },

    logoutAction: ({ commit }) => {
      localStorage.removeItem("xtenant");
      deleteCookie("access_token");
      localStorage.setItem("layout", "landing-layout");
      localStorage.removeItem("companyLogo");
      localStorage.removeItem("companyName");
      localStorage.removeItem("FullName");
      localStorage.removeItem("email");
      localStorage.removeItem("company_address");
      ls.set("randomID", null);
      delete AXIOS.defaults.headers.common["Authorization"];
      commit("logoutMutation");
      window.location.replace("/");
    },

    registerAction: async ({ commit }, payload) => {
      commit("set_loading", true);
      return axios
        .post(`${BaseURL}api/Registration`, {
          email: payload.email,
          password: payload.password,
          cmpany_name: payload.company_name,
          Industrial_type_id: payload.industrial_type,
          company_size: payload.company_size,
          mobile_number: payload.phone,
          tier_id: payload.teir_id,
          currency_id: payload.currency,
          first_name: payload.firstName,
          last_name: payload.lastName,
        })
        .then((res) => {
          let response = res.data;
          response = response.substring(2);
          let objectRes = JSON.parse(response);
          if (res.status == 200) {
            
            localStorage.setItem("signup_done", true);
            router.push({ path: `/thank-you` });
            localStorage.setItem("xtenant", objectRes.GetTenantID);
            localStorage.setItem("TenantEmail", objectRes.UserData.user.Email);
            commit("set_loading", true);
            payload.disabled = false;
            if (objectRes.UserData.message) {
              warningToast("Email or Password is not correct!");
              commit("set_loginLoading", false);
            } else {
              localStorage.setItem("xtenant", objectRes.GetTenantID);

              ls.set("randomID", objectRes.DaysTrail);

              ls.set("code", objectRes.code);

              ls.set("SubscriptionStatus", objectRes.SubscriptionStatus);

              commit("set_days", objectRes.TrialDays);

              storeCookies("access_token", objectRes.UserData.accessToken);

              localStorage.setItem("FullName", objectRes.FullName);

              localStorage.setItem("layout", "dashboard-layout");

              localStorage.setItem("email", objectRes.UserData.user.Email);
              localStorage.setItem("tierId", objectRes.tier);
              localStorage.setItem("role", objectRes.UserData.user.Role);

              localStorage.setItem(
                "company_address",
                objectRes.UserData.user.CompanyBillingSettings
              );

              if (objectRes.UserData.user.CompanyLogo) {
                localStorage.setItem(
                  "companyLogo",
                  `${
                    objectRes.UserData.user.CompanyLogo
                  }?XTenant=${localStorage.getItem("xtenant")}`
                );
              }

              localStorage.setItem(
                "companyName",
                objectRes.UserData.user.CompanyName
              );

              ls.set("perToken", objectRes.UserData.user.Role);

              localStorage.setItem(
                "cur_data",
                JSON.stringify(objectRes.CurrencyData)
              );
            }
          }
        })
        .catch((error) => {
          if (error?.response?.status === 409) {
            commit("set_loading", false);
            return warningToast(error.response.data.message);
          }
          commit("set_loading", false);
          localStorage.setItem("otp_done", false);
        });
    },
    registerActionNew: async ({ commit }, payload) => {
      commit("set_loading", true);
      return axios
        .post(`${BaseURL}api/Registration`, {
          email: payload.email,
          password: payload.password,
          cmpany_name: payload.company_name,
          Industrial_type_id: payload.industrial_type,
          company_size: payload.company_size,
          mobile_number: payload.phone,
          tier_id: payload.teir_id,
          currency_id: payload.currency,
          first_name: payload.firstName,
          last_name: payload.lastName,
        })
        .then((res) => {
          let response = res.data;
          let objectRes = response
          if (res.data.status === 200) {
            localStorage.setItem("signup_done", true);
            localStorage.setItem("xtenant", objectRes.GetTenantID);
            localStorage.setItem("TenantEmail", objectRes.UserData.user.Email);
            commit("set_loading", true);
            payload.disabled = false;
            if (objectRes.UserData.message) {
              warningToast("Email or Password is not correct!");
              commit("set_loginLoading", false);
            } else {
              localStorage.setItem("xtenant", objectRes.GetTenantID);

              ls.set("randomID", objectRes.DaysTrail);

              ls.set("code", objectRes.code);

              ls.set("SubscriptionStatus", objectRes.SubscriptionStatus);

              commit("set_days", objectRes.TrialDays);

              storeCookies("access_token", objectRes.UserData.accessToken);

              localStorage.setItem("FullName", objectRes.FullName);

              localStorage.setItem("layout", "dashboard-layout");

              localStorage.setItem("email", objectRes.UserData.user.Email);
              localStorage.setItem("tierId", objectRes.tier);
              localStorage.setItem("role", objectRes.UserData.user.Role);

              localStorage.setItem(
                "company_address",
                objectRes.UserData.user.CompanyBillingSettings
              );

              if (objectRes.UserData.user.CompanyLogo) {
                localStorage.setItem(
                  "companyLogo",
                  `${
                    objectRes.UserData.user.CompanyLogo
                  }?XTenant=${localStorage.getItem("xtenant")}`
                );
              }

              localStorage.setItem(
                "companyName",
                objectRes.UserData.user.CompanyName
              );

              ls.set("perToken", objectRes.UserData.user.Role);

              localStorage.setItem(
                "cur_data",
                JSON.stringify(objectRes.CurrencyData)
              );
            }
            router.push({ path: `/thank-you` });
          }
        })
        .catch((error) => {
          if (error?.response?.status === 409) {
            commit("set_loading", false);
            return warningToast(error.response.data.message);
          }
          commit("set_loading", false);
          localStorage.setItem("otp_done", false);
        });
    },

    registerActionEvent: ({ commit }, payload) => {
      commit("set_loading", true);
      return axios
        .post(`${BaseURL}api/Registration`, {
          email: payload.email,
          password: payload.password,
          cmpany_name: payload.company_name,
          Industrial_type_id: payload.industrial_type,
          company_size: payload.company_size,
          mobile_number: payload.phone,
          tier_id: 2,
          currency_id: payload.currency,
          first_name: payload.firstName,
          last_name: payload.lastName,
          promocode: payload.promocode,
        })
        .then((res) => {
          console.log(res);
          // "nt{"UserData":{"user":{"ID":1,"Email":"name822299@gmail.com","OTPCheck":true,"team_id":null,"CompanyName":"wd","CompanyBillingSettings":null,"permissions":{"invoice":["create","update"]},"CompanyLogo":null,"Role":"admin"},"accessToken":"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiYzI3MzRmMzZjZTUxNTk0YTIxNmY1Zjc2NTNmMzBlMDk0MGE4NDkzYWYzOGM5ZmIzNjk4NzEzNDg2MjE1ODk4NDFiZmI0YjBmZjAyMjE4N2QiLCJpYXQiOjE2OTQwNzUzNTQuMDYwMzM3MDY2NjUwMzkwNjI1LCJuYmYiOjE2OTQwNzUzNTQuMDYwMzM5OTI3NjczMzM5ODQzNzUsImV4cCI6MTcyNTY5Nzc1NC4wNTcwODQwODM1NTcxMjg5MDYyNSwic3ViIjoiMSIsInNjb3BlcyI6W119.SaWtyRw2U8wXtFcLZI-AOBgoJN3mXGcevI0InSLs86BNEzKx-wMhqKP1x4J9l6DkMswYNmEApjfjCxwWMyMF_w1iVxMo-XX1mWBxmqUFcDpzhfs12EXxZxXoFV-ynClY92paz9rDakWQLTK35_buw7y5ke8_tdEI10A6QL4pKYvFbUnaD36M8pwVNRmzxCRVe_ZL8a-3UYlpcijvpAm7SiJ-MbACaHDK_4akWvTshO63dpeF0AdFWtTplfmBY1KFr9qWcR2QUGrZ04G0LoEOV708_mSNaTkqjn59Oe8BOnrVxMmWfl95ljGb1Z1p_16YVcrzpscJsZYBQcLu_fgqONz2D7dKi0kzNFiR8NTgubIAg8TenLCITQWS3lCdXT_pTgraLe8MjpQz18W-hYiUI-Z8sPXvWXH7F4doWzLjUY_SJJ9eiWLrarethTxmyOCoA8O7DEOq3zvvyweWGnv1b7EVUOx5lUXj6cSpSgj77wPQlmu57bcJ-LHvqfvEoZ0KVEaO_5QYHaAjBpmXkIUxU3vWr8-cxUEM7RG178WMm1k4KiInkX0XBwfge2vX4DrpeEByaHZKUom3eeHvYImbKIEqZqhnEHP7Pb-09cVewbC0hx2ULwGiNcIiQZBfFYDz3YrqqfRJpeZLADJXoouCOaTiXr0knfXQLtFRnQ8Grks"},"GetTenantID":"a19b93ca-6680-458c-83c0-802dd68c6770","FullNmae":"","code":"XA07","DaysTrail":29,"SubscriptionStatus":"trial","CurrencyData":{"currency_id":30,"name":"Egyptian Pound","symbol":"\u00a3","code":"EGP","created_at":"2022-09-18T02:25:05.000000Z","updated_at":"2022-09-18T02:25:05.000000Z"},"tier":2,"Branch":true}"

          // console.log(res.data)
          // const response = JSON.parse(res.data);
          let response = res.data;
          response = response.substring(2);
          let objectRes = JSON.parse(response);
          console.log(objectRes);

          if (res.status == 200) {
            localStorage.setItem("signup_done", true);
            // router.push({ path: `/dashboard` });
            localStorage.setItem("xtenant", objectRes.GetTenantID);
            localStorage.setItem("TenantEmail", objectRes.UserData.user.Email);
            commit("set_loading", true);
            payload.disabled = false;
            if (objectRes.UserData.message) {
              warningToast("Email or Password is not correct!");
              commit("set_loginLoading", false);
            } else {
              localStorage.setItem("xtenant", objectRes.GetTenantID);

              ls.set("randomID", objectRes.DaysTrail);

              ls.set("code", objectRes.code);

              ls.set("SubscriptionStatus", objectRes.SubscriptionStatus);

              commit("set_days", objectRes.TrialDays);

              storeCookies("access_token", objectRes.UserData.accessToken);

              // commit("loginMutation");

              localStorage.setItem("FullName", objectRes.FullName);

              localStorage.setItem("layout", "dashboard-layout");

              localStorage.setItem("email", objectRes.UserData.user.Email);
              localStorage.setItem("tierId", objectRes.tier);
              localStorage.setItem("role", objectRes.UserData.user.Role);

              localStorage.setItem(
                "company_address",
                objectRes.UserData.user.CompanyBillingSettings
              );

              if (objectRes.UserData.user.CompanyLogo) {
                localStorage.setItem(
                  "companyLogo",
                  `${
                    objectRes.UserData.user.CompanyLogo
                  }?XTenant=${localStorage.getItem("xtenant")}`
                );
              }

              localStorage.setItem(
                "companyName",
                objectRes.UserData.user.CompanyName
              );

              ls.set("perToken", objectRes.UserData.user.Role);

              localStorage.setItem(
                "cur_data",
                JSON.stringify(objectRes.CurrencyData)
              );

              location.reload();
            }
          }
          return res;
          // localStorage.removeItem('tierId')
        })
        .catch((error) => {
          error.message;
          if (error.response.status == 409) {
            // Vue.$toast.warning(error.response.data.message, {
            //     timeout: 5000
            // })
            warningToast(error.response.data.message);
            commit("set_loading", false);
          }
          commit("set_loading", false);
          localStorage.setItem("otp_done", false);
        });
    },

    get_industrial_types_action: ({ commit }) => {
      axios
        .get(`${BaseURL}api/GetAllIndustties`)
        .then((res) => {
          commit("set_industrial_types", res.data.Industry);
        })
        .catch((err) => {
          err.message;
        });
    },

    verify_otp_action: ({ commit }, payload) => {
      axios
        .post(
          `${BaseURL}Auth/VerifyOtp`,
          {
            otp: payload.otp,
          },
          {
            headers: {
              XTenant: `${localStorage.getItem("xtenant")}`,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            router.push({ path: `/login` });
            // Vue.$toast.success('Validation success', {
            //     timeout: 5000
            // })
            successToast("Validation success");
            commit("set_invalidOtp", false);
            localStorage.setItem("invalidOtp", false);
          }
        })
        .catch((error) => {
          error.message;
          if (error.response.status == 422) {
            // Vue.$toast.warning(error.response.data.message, {
            //     timeout: 5000
            // })
            warningToast(error.response.data.message);
            commit("set_invalidOtp", true);
            localStorage.setItem("invalidOtp", true);
          }
        });
    },

    resend_otp_action: ({ commit }) => {
      axios
        .post(`${BaseURL}Auth/RequestOtp`, {
          email: localStorage.getItem("TenantEmail"),
        })
        .then((res) => {})
        .catch((err) => {
          err.message;
        });
    },

    setEmailExistMsgAction: ({ commit }, data) => {
      commit("set_emailExistMsg", data);
    },

    get_currencies_action: ({ commit, state }) => {
      axios
        .get(`${BaseURL}api/AllCurrencies`)
        .then((res) => {
          commit("set_currencies", res.data);
          commit(
            "set_currency",
            state.currencies[state.currencies.length - 72]
          );
        })
        .catch((err) => {
          err.message;
        });
    },

    event_submit_action: ({ commit }, payload) => {
      commit("set_loading", true);
      axios
        .post(`${BaseURL}api/Registration`, {
          email: payload.email,
          password: payload.password,
          cmpany_name: payload.company_name,
          Industrial_type_id: 1,
          company_size: "5-11",
          mobile_number: payload.phone,
          tier_id: 2,
          currency_id: 30,
          first_name: payload.firstName,
          last_name: payload.lastName,
          user_type: payload.userRole,
        })
        .then((res) => {
          commit("set_loading", false);
          // Vue.$toast('Your data submited successfully, Thank you!', {
          //     timeout: 5000
          // })
          successToast("Your data submited successfully, Thank you!");
        })
        .catch((error) => {
          error.message;
          if (error.response.status == 422 || error.response.status == 409) {
            // Vue.$toast.warning(error.response.data.message, {
            //     timeout: 5000
            // })
            warningToast(error.response.data.message);
            commit("set_loading", false);
          }
        });
    },

    confirm_email_action: ({ commit }, payload) => {
      axios
        .post(`${BaseURL}api/ResetPassword`, {
          email: payload.email,
        })
        .then((res) => {
          if (res.status == 200) {
            // Vue.$toast.success('A recovery mail has been sent to your email.', {
            //     timeout: 5000
            // })
            successToast("A recovery mail has been sent to your email.");
            router.push({ path: "/login" });
          }
        })
        .catch((error) => {
          if (error.response.status == 409) {
            // Vue.$toast.warning('The email you entered dosen\'t exit!', {
            //     timeout: 5000
            // })
            warningToast("The email you entered dosen't exit!");
          }
        });
    },

    reset_password_action: ({ commit }, payload) => {
      axios
        .post(
          `${BaseURL}api/ResetPasswordStepTwo`,
          {
            token: payload.token,
            password: payload.password,
            confirmation_password: payload.confirmPassword,
          },
          {
            headers: {
              XTenant: payload.tenantId,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            // Vue.$toast.success('Password reseted successfully! Now you can login with new password.', {
            //     timeout: 5000
            // })
            successToast(
              "Password reseted successfully! Now you can login with new password."
            );
            router.push({ path: "/login" });
          }
        })
        .catch((err) => {
          err.message;
          // Vue.$toast.error('Something went wrong!', {
          //     timeout: 5000
          // })
          errorToast("Something went wrong!");
        });
    },

    confirm_invitation_action: ({ commit }, payload) => {
      axios
        .post(`${BaseURL}api/ConfirmMemberPassword`, {
          email: payload.email,
          password: payload.password,
          tenant_id: payload.tenantId,
        })
        .then((res) => {
          successToast("Password set successfully!");
          router.push({ path: "/login" });
        })
        .catch((err) => {
          err.message;
        });
    },
  },
  modules: {},
};
